import {addBookingsPage} from '../pages-actions';
import {MigrationFailReason, PageId} from '../../constants';
import {
  getAllSiteComponents,
  getFullComponentStructure,
  updateComponentStyle,
  updateControllerConfiguration,
} from '../editor-sdk-actions';
import { ComponentStructure, ComponentType } from '../../migration/domain';
import { getNewServicePageStyles } from './styles-transpiler';
import { getNewServicePageData } from './settings-data-transpiler';
import {closeBar, openBar, setBarStep} from '../progressbar-utils';

export async function handleServicePageMigration(sdk, appToken, instance, translations, enableProgressBar = true) {
  if (enableProgressBar) {
    await openBar(sdk, appToken, translations,'bookings-migration.progress-bar.title', 3);
    await setBarStep(sdk, appToken, translations,'service-page-migration.progress-bar.step',  1);
  }
  try {
    await addBookingsPage(PageId.BOOKINGS_SERVICE_PAGE, sdk, appToken);
  } catch {
    enableProgressBar && closeBar(sdk, appToken)
    throw MigrationFailReason.PAGES;
  }
  const allComponents = await getAllSiteComponents(sdk, appToken);
  const siteStructure: any[] = await (async () => {
    return await Promise.all(allComponents.map(componentRef =>
      getFullComponentStructure(sdk, appToken, componentRef)
    ));
  })();
  const bookingsCheckoutWidgetId = '713f72a2-7a32-47e9-b5e4-6024ee57d277';
  const servicePageWidgetId = 'a91a0543-d4bd-4e6b-b315-9410aa27bcde';
  const compStructuresCheckout: ComponentStructure = siteStructure.find(comp => comp.type === ComponentType.COMPONENT && comp.data?.widgetId === bookingsCheckoutWidgetId);
  const compStructuresServicePage: ComponentStructure = siteStructure.find(comp => comp.type === ComponentType.COMPONENT && comp.data?.widgetId === servicePageWidgetId);
  if (compStructuresCheckout.style?.style?.properties) {
    console.log('Old Service Page Properties:');
    console.log(compStructuresCheckout);
    console.table(compStructuresCheckout.style.style.properties);
    console.log('New Service Page structure: (Before Change)');
    console.log(compStructuresServicePage);
    console.table(compStructuresServicePage.style?.style?.properties);
    const newServicePageData = getNewServicePageData(compStructuresCheckout.style.style.properties);
    console.log('Layouts to write:');
    console.table(newServicePageData);
    enableProgressBar && await setBarStep(sdk, appToken, translations,'service-page-migration.progress-bar.step',  2);
    if (newServicePageData) {
      try {
        await updateControllerConfiguration(sdk, appToken, compStructuresServicePage.id, newServicePageData);
      } catch {
        enableProgressBar && closeBar(sdk, appToken)
        throw MigrationFailReason.DATA;
      }
    }
    const newServicePageStyles = getNewServicePageStyles(compStructuresCheckout.style.style.properties);
    console.log('Styles to write:');
    console.table(newServicePageStyles);
    enableProgressBar && await setBarStep(sdk, appToken, translations,'service-page-migration.progress-bar.step',  3);
    if (newServicePageStyles) {
      try {
        await updateComponentStyle(sdk, appToken, compStructuresServicePage.id, newServicePageStyles);
      }
      catch {
        enableProgressBar && closeBar(sdk, appToken)
        throw MigrationFailReason.STYLES;
      }
    }
  } else {
    console.log('Old service page is plain, no styles to migrate.')
  }

  const mediaMigrationHeaders = new Headers();
  mediaMigrationHeaders.append("Content-Type", "application/json");
  mediaMigrationHeaders.append("authorization", instance);

  try {
    fetch("https://editor.wix.com/internal/services-server/media-migration", {
      method: 'POST',
      headers: mediaMigrationHeaders,
      body: JSON.stringify({}),
      redirect: 'follow'
    })
  } catch {
    enableProgressBar && closeBar(sdk, appToken)
    throw MigrationFailReason.DATA;
  }
  enableProgressBar && closeBar(sdk, appToken)

  return Promise.resolve();
}
