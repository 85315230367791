import {getEditorSdkSrc} from './editor-sdk-actions';
import {handleListMigration} from './list-migration/list-migration';
import {handleServicePageMigration} from './service-page-migration/service-page-migration';
import {handlePagesPanelMigration} from './migrate-actions';
import editor from '@wix/platform-editor-sdk/lib/js/modules/editor';
import {MigrationFailReason} from '../constants';
import {createServicePageState} from './pages-panel-actions';

const BOOKINGS_URL_PREFIX = 'https://bookings.wixapps.net';
const BOOKINGS_EDITOR_MODALS = 'bookings-editor-modals';

const handleMigrationProposalResult = async (migrationProposalResult, editorSdk, wixEditorSdkSrc, locale, appToken, instance, translation, allSitePages) => {
  if (migrationProposalResult.status && migrationProposalResult.status === 'confirmed') {
    try {
      await handleListMigration(editorSdk, appToken, translation);
      await handlePagesPanelMigration(editorSdk, appToken, allSitePages);
      await editorSdk.document.save();
      await openMigrationSucceedModal(editorSdk, wixEditorSdkSrc, locale, instance);
    } catch (e) {
      console.log('failed to migrate bookings', e);
      await openMigrationFailedModal(editorSdk, wixEditorSdkSrc, locale, instance);
    }
  }
};

const openModal = ({editorSdk, wixEditorSdkSrc, locale, modal, width, height, instance, reason = undefined}) => {
  const url = `${BOOKINGS_URL_PREFIX}/${BOOKINGS_EDITOR_MODALS}?wixEditorSdkSrc=${wixEditorSdkSrc}&modal=${modal}&locale=${locale}&instance=${instance}`
  return editorSdk.editor.openModalPanel('token', {
    url: reason ? `${url}&reason=${reason}` : url,
    shouldHideHeader: true,
    width,
    height,
    centered: true
  });
};

const openMigrationProposalModal = (editorSdk, wixEditorSdkSrc, locale, instance) =>
  openModal({editorSdk, wixEditorSdkSrc, locale, instance, modal: 'split_migration_proposal', width: 504, height: 486});

const openMigrationFailedModal = (editorSdk, wixEditorSdkSrc, locale, instance) =>
  openModal({editorSdk, wixEditorSdkSrc, locale, instance, modal: 'split_migration_failure', width: 564, height: 284});

const openMigrationSucceedModal = (editorSdk, wixEditorSdkSrc, locale, instance) =>
  openModal({editorSdk, wixEditorSdkSrc, locale, instance, modal: 'split_migration_success', width: 468, height: 486});

export const proposeListMigration = async (editorSdk, appToken, locale, instance, translation, allSitePages) => {
  const wixEditorSdkSrc = getEditorSdkSrc(editorSdk);
  const migrationProposalResult = await openMigrationProposalModal(editorSdk, wixEditorSdkSrc, locale, instance);
  await handleMigrationProposalResult(migrationProposalResult, editorSdk, wixEditorSdkSrc, locale, appToken, instance, translation, allSitePages);
};

export const proposeServicePageMigration = async (editorSdk, appToken, locale, instance, translation) => {
  const wixEditorSdkSrc = getEditorSdkSrc(editorSdk);
  const migrationProposalResult = await openServicePageMigrationProposalModal(editorSdk, wixEditorSdkSrc, locale, instance);
  await handleServicePageMigrationResult(migrationProposalResult, editorSdk, wixEditorSdkSrc, locale, appToken, instance, translation);
}

const openServicePageMigrationProposalModal = (editorSdk, wixEditorSdkSrc, locale, instance) =>
  openModal({editorSdk, wixEditorSdkSrc, locale, instance, modal: 'service_page_migration_proposal', width: 500, height: 550});

const openServicePageMigrationSuccessModal = (editorSdk, wixEditorSdkSrc, locale, instance) =>
  openModal({editorSdk, wixEditorSdkSrc, locale, instance, modal: 'service_page_migration_success', width: 504, height: 468});

const openServicePageMigrationFailedModal = (editorSdk, wixEditorSdkSrc, locale, instance, reason: MigrationFailReason) =>
  openModal({editorSdk, wixEditorSdkSrc, locale, instance, modal: 'service_page_migration_failure', width: 564, height: 312, reason});

const handleServicePageMigrationResult = async (migrationProposalResult, editorSdk, wixEditorSdkSrc, locale, appToken, instance, translation) => {
  if (migrationProposalResult?.status === 'confirmed') {
    try {
      await handleServicePageMigration(editorSdk, appToken, instance, translation);
      const newAllSitePages = await editorSdk.pages.data.getAll();
      await handlePagesPanelMigration(editorSdk, appToken, newAllSitePages);
      await createServicePageState(editorSdk, appToken, newAllSitePages);
      await editorSdk.document.save();
      await openServicePageMigrationSuccessModal(editorSdk, wixEditorSdkSrc, locale, instance);
    } catch (e) {
      console.log('failed to migrate bookings', e);
      await openServicePageMigrationFailedModal(editorSdk, wixEditorSdkSrc, locale, instance, e);
    }
  }
};
